@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../../utilities/globalmixins";

.image-and-text-container {
    .title {
        font-weight: 700;
        margin: 0;
    }

    .button {
        padding: 0.5rem 1.5rem;

        span::after {
            content: "\f0da";
            font-family: "FontAwesome";
            margin-left: 5px;
        }
    }
}
